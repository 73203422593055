<template>
  <div class="matrix-block container relative max-w-screen-md">
    <div v-if="index" class="lg:absolute lg:top-0 lg:-left-20 mb-4 fluid-3xl leading-tightest font-semibold">
      {{ index | indexPad }}
    </div>
    <CraftAsset
      v-if="image[0]"
      :alt="alt"
      :asset="image[0]"
      :ratio="2 / 3"
      :width="700"
      crop="fill"
      :autoplay="autoplay"
    />
    <div class="text-copy fluid-lg font-light leading-normal" v-html="body"></div>
  </div>
</template>
<script>
import CraftAsset from '@/components/common/CraftAsset';
import { indexPad } from '@/utils/common';

export default {
  components: {
    CraftAsset,
  },
  filters: {
    indexPad,
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    image: {
      type: Array,
      default: () => [],
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    body: {
      type: String,
      default: '',
    },
    crop: {
      type: String,
      default: null,
    },
  },
};
</script>
