<template>
  <div class="container relative">
    <div class="sm:grid-cols-3 gap-x-12 gap-y-8 grid max-w-screen-md mx-auto">
      <div v-for="(set, index) in sets" :key="index" class="relative flex items-center">
        <CldnImg
          v-if="set.image[0]"
          :src="set.image[0].url"
          sizes="150px"
          :alt="set.alt ? set.alt : set.image[0].title"
          class="max-w-none block w-24"
        />
        <div v-if="set.caption" class="fluid-xs leading-tightest pl-4" v-html="set.caption"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';

export default {
  components: { CldnImg },
  props: {
    sets: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
