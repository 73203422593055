<template>
  <div class="relative overflow-hidden">
    <CraftAsset
      v-if="image"
      :alt="image.url"
      :asset="image"
      :ratio="0.55"
      sizes="100vw"
      crop="fill"
      :autoplay="true"
      :fullscreen="true"
      :controls="false"
      :muted="false"
      :hfull="true"
      class="max-w-none xl:hidden absolute inset-0 z-0 block object-cover w-full h-full"
    />
    <CraftAsset
      v-if="image"
      :alt="image.url"
      :asset="image"
      :ratio="0.35"
      sizes="100vw"
      crop="fill"
      :autoplay="true"
      :fullscreen="true"
      :controls="false"
      :muted="false"
      :hfull="true"
      class="max-w-none xl:block absolute inset-0 z-0 hidden object-cover w-full h-full"
    />
    <div class="absolute inset-0">
      <slot />
    </div>
  </div>
</template>

<script>
import CraftAsset from '@/components/common/CraftAsset'

export default {
  components: {
    CraftAsset
  },
  props: {
    image: {
      type: Object,
      default: () => null
    }
  }
}
</script>
