<template>
  <svg viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.647 9.308c.714 0 1.36-.26 1.836-.68l5.245 2.402a2.254 2.254 0 0 0-.022.299c0 1.339 1.185 2.425 2.647 2.425 1.462 0 2.647-1.086 2.647-2.425 0-1.34-1.185-2.426-2.647-2.426-.714 0-1.36.26-1.836.68L5.272 7.182c.013-.098.022-.197.022-.299 0-.101-.009-.2-.022-.298l5.245-2.403c.476.42 1.122.68 1.836.68C13.815 4.861 15 3.776 15 2.436c0-1.34-1.185-2.425-2.647-2.425-1.462 0-2.647 1.086-2.647 2.425 0 .102.009.2.022.299L4.483 5.137a2.767 2.767 0 0 0-1.836-.68C1.185 4.457 0 5.543 0 6.882c0 1.34 1.185 2.426 2.647 2.426Z"
      fill="#E2EED6"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {}
</script>
