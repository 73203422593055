<template>
  <div class="article-chapter relative">
    <div :id="chapterId" class="absolute" :class="{ '-mt-4': !image[0] }" />
    <div v-if="image[0]" class="matrix-block md:pb-20 md:mt-40 relative pb-20 mt-12">
      <HeroImage :image="image[0]" />
      <div v-if="heading" class="absolute inset-0 z-10 flex flex-col justify-end">
        <div
          :class="{ 'justify-end': boxRight, 'justify-start': !boxRight }"
          class="to sm:max-w-screen-lg sm:container box-wrapper flex flex-row"
        >
          <div class="bg-blackly text-white p-8" :class="extendHeadingBackground(heading)">
            <div v-if="index" class="fluid-4xl leading-none font-medium">{{ index | indexPad }}</div>
            <h2 class="lg:mt-4 text-br-green-light mt-2 fluid-xl leading-tight font-medium">{{ heading }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="heading" class="container">
      <div class="max-w-screen-md mx-auto">
        <h2 class="my-4 fluid-xl leading-tight font-medium">{{ heading }}</h2>
      </div>
    </div>
  </div>
</template>
<script>
import { indexPad } from '@/utils/common';
import HeroImage from '@/components/common/HeroImage';

export default {
  name: 'ArticleChapter',
  components: {
    HeroImage,
  },
  filters: {
    indexPad,
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
    chapterId: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: '',
    },
    image: {
      type: Array,
      default: () => [],
    },
    boxRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // need to use a method to check if single word length exceeds 16
    // if min class prefixes were used without it, words in heading wouldn't break into next lines
    extendHeadingBackground: (heading) => {
      let backgroundWidth = 'sm:w-6/12 md:w-5/12 lg:w-5/12 w-9/12';
      heading.split(' ').forEach((word) => word.length > 16 && (backgroundWidth = 'sm:min-w-6/12 min-w-9/12'));
      return backgroundWidth;
    },
  },
};
</script>
