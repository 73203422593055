<template>
  <div class="container max-w-screen-md my-l">
    <div class="text-center font-semibold mb-12 fluid-3xl" v-html="heading"></div>
    <div class="mb-12 lg:w-2/3 m-auto">
      <ModelSelect
        v-if="contactOptions.length"
        v-model="contactId"
        :options="contactOptions"
        :placeholder="$t('education.enterLocation')"
        class="contact-dropdown"
      />
    </div>
    <div v-if="selectedContact" class="w-full text-center text-copy" v-html="selectedContact.body"></div>
    <div class="flex flex-wrap text-copy divide-y sm:divide-x sm:divide-y-0 mt-10">
      <div class="w-full sm:w-1/2 py-10 text-center">
        <strong class="block">{{ $t('phone') }}</strong>
        <div v-if="selectedContact">
          <a :href="'tel:' + selectedContact.telephone">{{ selectedContact.telephone }}</a>
          <em class="block not-italic">{{ selectedContact.phoneDescription }}</em>
        </div>
      </div>
      <div class="w-full sm:w-1/2 py-10 text-center">
        <strong>{{ $t('email') }}</strong>
        <div v-if="selectedContact">
          <a :href="'mailto:' + selectedContact.email">{{ selectedContact.email }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';

export default {
  components: {
    ModelSelect,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    sections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contactId: null,
    };
  },
  computed: {
    contactOptions() {
      const filterEmpty = this.sections.filter((section) => !!section.heading);
      return filterEmpty.map((section) => ({ value: section.id, text: section.heading }));
    },
    selectedContact() {
      return this.sections?.find((section) => section.id === this.contactId) || null;
    },
  },
  mounted() {
    this.contactId = this.sections.length && this.sections[0].id;
  },
};
</script>

<style scoped>
.ui.selection.dropdown.contact-dropdown {
  @apply border-none py-5 bg-gray-200;
  &:not(.active) {
    @apply rounded-full;
  }
}
.ui.selection.dropdown.contact-dropdown >>> .menu {
  @apply border-none shadow-xl;
}
.ui.selection.dropdown.contact-dropdown >>> .search {
  @apply py-5;
}
.ui.selection.dropdown.contact-dropdown >>> .icon {
  @apply p-5;
}
</style>
