<template>
  <div class="my-l container lg:grid-cols-12 gap-x-16 grid">
    <div
      class="gap-x-16 lg:grid-cols-2 xl:col-start-2 xl:col-end-12 lg:col-start-1 lg:col-end-13 gap-y-12 lg:gap-y-0 grid"
    >
      <div>
        <div v-if="body" class="fluid-base leading-relaxed font-light pr-4 content-with-icons-body" v-html="body"></div>
      </div>
      <div class="gap-x-16 gap-y-12 md:grid-cols-2 grid">
        <div v-for="(card, index) in contentWithIconsCards" :key="index" data-testid="cards">
          <img :src="card.icon[0].url" class="h-8" :alt="card.heading" />
          <h3 v-if="card.heading" class="fluid-lg leading-tight font-medium mt-3" v-html="card.heading"></h3>
          <div v-if="card.body" class="fluid-sm tracking-wider mt-4 font-light" v-html="card.body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    body: {
      type: String,
      default: null,
    },
    contentWithIconsCards: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
