<template>
  <div class="my-l container headline md:px-24 xl:px-32 flex justify-center flex-col items-center text-center">
    <span class="inline-block sm:mt-6 mb-4 fluid-xs leading-tightest text__bg--highlight" v-html="tagLine"></span>
    <h3 v-if="heading" class="fluid-3xl leading-tightest font-bold whitespace-pre-line" v-html="heading"></h3>
  </div>
</template>

<script>
import gql from 'graphql-tag'



export default {
  props: {
    heading: {
      type: String,
      default: null
    },
    tagLine: {
      type: String,
      default: null
    }
  }
}
</script>
