<template>
  <div v-if="expert" class="md:mx-5 xl:mx-10">
    <figure class="relative">
      <fixed-aspect :ratio="2 / 3">
        <CldnImg
          v-if="expert.image[0]"
          :src="expert.image[0].url"
          :sizes="'(min-width: 1440px) 548px,(min-width: 1024px) 50vw, 100vw'"
          crop="fill"
          :ratio="2 / 3"
          class="max-w-none block w-full transition-transform duration-700 transform"
          :alt="expert.alt ? expert.alt : expert.image[0].title"
        />
      </fixed-aspect>
      <figcaption v-if="expert.expert" class="md:absolute md:m-2 lg:m-4 xl:m-8 bottom-0 mt-2">
        <BarText
          v-if="expert.expert[0]"
          tag="div"
          :text="expert.expert[0].title"
          class="bg-blackly fluid-xl leading-tight font-medium inline-block w-auto px-2 text-white"
        />
        <strong class="lg:mt-2 block mt-2">
          <span class="btn-tag-fill fluid-lg font-light leading-normal inline-block w-auto px-2 pointer-events-none">{{
            expert.expertise
          }}</span>
        </strong>
      </figcaption>
    </figure>
    <div class="lg:px-8 my-4">
      <p v-if="expert.quote">{{ expert.quote }}</p>
      <nuxt-link
        v-if="expert.expert[0]"
        :to="$localeRootPath(expert.expert[0].uri)"
        class="btn-pill-outline-black mt-3"
        >{{ $t('viewProfile') }}</nuxt-link
      >
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import BarText from '@/components/BarText.vue';
import FixedAspect from '@/components/common/FixedAspect';

export default {
  name: 'ExpertPreview',
  components: {
    FixedAspect,
    CldnImg,
    BarText,
  },
  props: {
    expert: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
