<template>
  <div class="sm:justify-start matrix-block max-w-expanded-md relative flex flex-row justify-end mx-auto">
    <div class="sm:relative md:px-0 sm:pb-12 sm:w-3/5 md:w-[56%] absolute inset-x-0 top-0 w-full p-8">
      <FixedAspect :ratio="1" class="">
        <CldnImg
          v-if="image[0]"
          :src="image[0].url"
          :alt="image[0].title"
          sizes="(min-width: 1024px) 483px, 50vw"
          crop="fill"
        />
      </FixedAspect>
    </div>
    <div
      class="sm:absolute sm:bottom-0 sm:right-0 sm:mr-8 mt-[80%] w-4/5 sm:mt-[20%] sm:w-[45%] sm:min-h-[70%] z-10 flex flex-col justify-between box"
    >
      <div class="text-copy mb-8 fluid-base leading-normal font-medium" v-html="body"></div>
      <div class="text-br-green mt-auto fluid-xs leading-tightest">{{ testimonial }}</div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import FixedAspect from '@/components/common/FixedAspect'
import CldnImg from '@/components/CldnImg'



export default {
  components: {
    FixedAspect,
    CldnImg
  },
  props: {
    body: {
      type: String,
      default: ''
    },
    testimonial: {
      type: String,
      default: ''
    },
    image: {
      type: Array,
      default: () => []
    }
  }
}
</script>
