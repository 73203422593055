<template>
  <div class="matrix-block md:px-0 sm:flex-row max-w-expanded-md container relative flex flex-col">
    <FixedAspect :ratio="1" class="sm:w-5/12 flex-shrink-0">
      <CldnImg
        v-if="image[0] && image[0].url"
        :src="image[0].url"
        :alt="image[0].title"
        :ratio="1"
        sizes="(min-width: 1024px) 450px, (min-width: 640px) 40vw, 100vw"
        crop="fill"
        data-test-id="image"
      />
    </FixedAspect>
    <div class="sm:pl-12">
      <div class="block text-sm">{{ tagLine }}</div>
      <div class="block text-copy fluid-sm leading-tightest font-medium mt-5 mb-2">{{ heading }}</div>
      <div class="text-copy fluid-base leading-relaxed font-light" v-html="body"></div>
    </div>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect'
import CldnImg from '@/components/CldnImg'

export default {
  components: {
    FixedAspect,
    CldnImg
  },
  props: {
    tagLine: {
      type: String,
      default: null
    },
    heading: {
      type: String,
      default: null
    },
    body: {
      type: String,
      default: null
    },
    image: {
      type: Array,
      default: () => []
    }
  }
}
</script>
