<template>
  <div v-if="experts" class="container my-20 grid grid-cols-1 md:grid-cols-2 gap-10">
    <ExpertPreview v-for="(expert, index) in experts" :key="index" :expert="expert" class="col-span-1" />
  </div>
</template>

<script>
import ExpertPreview from '@/components/article/ExpertPreview';

export default {
  components: { ExpertPreview },
  props: {
    experts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
