<template>
  <svg viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)">
        <circle stroke="#162022" cx="19.5" cy="19.5" r="19.5" />
      </g>
      <path
        d="M21.625 17.844v-2.125c0-.587.504-1.063 1.125-1.063h1.125V12h-2.25c-1.864 0-3.375 1.427-3.375 3.188v2.656H16V20.5h2.25V29h3.375v-8.5h2.25L25 17.844h-3.375Z"
        fill="#162022"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>
