<template>
  <div class="progress-container h-[3px] fixed w-full z-100 bg-blackly">
    <div class="progress-bar h-full bg-br-green" :style="`width: ${progress}%`"></div>
  </div>
</template>

<script>
export default {
  name: 'ChapterNavBarProgress',
  data() {
    return { progress: 0 }
  },
  mounted() {
    window.addEventListener('scroll', this.updateProgress, { passive: true })
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateProgress)
  },
  methods: {
    updateProgress() {
      this.progress = (window.scrollY / (document.body.clientHeight - document.documentElement.clientHeight)) * 100
    }
  }
}
</script>
