<template>
  <FiftyFiftyTeaserWithBullets
    :image="image"
    :body="body"
    :cta="cta[0]"
    :alt="alt"
    :image-right="imageRight"
    :main-heading="mainHeading"
  >
    <div v-if="heading || subtitle">
      <h3 class="fluid-3xl leading-tightest font-semibold" v-html="heading"></h3>
      <h4 class="fluid-lg leading-tight font-medium mt-3 text__bg--highlight" v-html="subtitle"></h4>
    </div>
  </FiftyFiftyTeaserWithBullets>
</template>

<script>
import FiftyFiftyTeaserWithBullets from '@/components/common/blocks/FiftyFiftyTeaserWithBullets';

export default {
  components: {
    FiftyFiftyTeaserWithBullets,
  },
  props: {
    image: {
      type: Array,
      default: () => [],
    },
    imageRight: {
      type: Boolean,
      default: false,
    },
    mainHeading: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
