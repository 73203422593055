<template>
  <div class="relative">
    <FixedAspect :ratio="1" class="block sm:hidden">
      <CldnImg
        v-if="image[0]"
        :src="image[0].url"
        :alt="image[0].title"
        crop="fill"
        :ratio="1"
        :sizes="'100vw'"
        class="object-cover w-full"
      />
    </FixedAspect>
    <FixedAspect :ratio="0.5" class="hidden sm:block">
      <CldnImg
        v-if="image[0]"
        :src="image[0].url"
        :alt="image[0].title"
        crop="fill"
        :ratio="0.5"
        :sizes="'100vw'"
        class="object-cover w-full"
      />
    </FixedAspect>
    <div class="absolute inset-0 p-10">
      <div class="py-l flex flex-col items-center justify-center h-full">
        <BarText v-if="heading" tag="h2" :text="heading" class="fluid-5xl leading-none md:mt-20" />
        <div class="">
          <LinkField
            v-bind="cta[0]"
            class="sm:mt-6 btn-pill-outline-white inline-block mt-4"
            type-name="FullWidthImageCta"
            :tracking-name="stripHtml(heading)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkField from '~/components/common/LinkField';
import BarText from '~/components/BarText';
import CldnImg from '~/components/CldnImg';
import FixedAspect from '~/components/common/FixedAspect';
import { stripHtml } from '@/utils/common';

export default {
  components: {
    FixedAspect,
    BarText,
    CldnImg,
    LinkField,
  },
  props: {
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    stripHtml,
  },
};
</script>
