<template>
  <div
    id="shareThisArticle"
    class="border-gray-divider sm:flex-row container flex flex-col items-center justify-between py-6 border-t-2"
  >
    <span class="fluid-lg leading-tight font-medium">{{ headingText }}</span>
    <div class="sm:py-0 flex flex-row py-6">
      <a class="mx-1" :href="twitterLink" target="_blank">
        <TwitterBorderIcon class="h-10" />
      </a>
      <a class="mx-1" target="_blank" :href="facebookLink">
        <FacebookBorderIcon class="h-10" />
      </a>
      <a class="mx-1" :href="linkedinLink" target="_blank">
        <LinkedInBorderIcon class="h-10" />
      </a>
      <a class="mx-1" target="_blank" :href="pinterestLink">
        <PinterestBorderIcon class="h-10" />
      </a>
      <a class="mx-1" :href="mailLink" target="_blank"><EmailBorderIcon class="h-10" /></a>
    </div>
  </div>
</template>

<script>
import TwitterBorderIcon from '@/components/icons/TwitterBorderIcon';
import FacebookBorderIcon from '@/components/icons/FacebookBorderIcon';
import LinkedInBorderIcon from '@/components/icons/LinkedInBorderIcon';
import PinterestBorderIcon from '@/components/icons/PinterestBorderIcon';
import EmailBorderIcon from '@/components/icons/EmailBorderIcon';

export default {
  name: 'ShareThisArticle',
  components: { EmailBorderIcon, PinterestBorderIcon, LinkedInBorderIcon, FacebookBorderIcon, TwitterBorderIcon },
  props: {
    header: {
      type: String,
      default: null,
    },
  },
  data() {
    let url, title;
    const msg = this.$t('shareThisArticleMessage');
    return {
      url,
      title,
      msg,
    };
  },
  computed: {
    twitterLink() {
      return `https://twitter.com/intent/tweet?text=${this.msg + this.url}`;
    },
    linkedinLink() {
      return `https://www.linkedin.com/sharing/share-offsite?url=${this.url}`;
    },
    facebookLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
    },
    pinterestLink() {
      return `https://www.pinterest.com/pin/create/button?url=${this.url}`;
    },
    mailLink() {
      return `mailto:?subject=${this.title}&body=${this.msg + this.url}`;
    },
    webShareApiSupported() {
      if (process.server || !window.navigator) return false;
      return !!window.navigator?.share;
    },
    headingText() {
      return this.header || this.$t('shareThisArticle');
    },
  },
  mounted() {
    this.url = window.location.href;
    this.title = document.title;
  },
};
</script>
