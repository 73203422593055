<template>
  <svg
    class="transition duration-200 transform"
    :class="{ '-rotate-90': down }"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6 5"
  >
    <path fill="#93AB39" fill-rule="evenodd" d="M0 0h6L3 5z" />
  </svg>
</template>

<script>
export default {
  props: {
    down: {
      type: Boolean,
      default: true
    }
  }
}
</script>
