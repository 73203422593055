<template>
  <div class="container relative">
    <div class="max-w-screen-md mx-auto">
      <table class="fluid-xs leading-tightest font-medium bg-gray-100">
        <tbody>
          <tr v-for="(row, index) in table" :key="index">
            <td v-if="row.first" class="md:p-4 p-2 border-b-2 border-white" v-html="row.first"></td>
            <td v-if="row.second" class="md:p-4 p-2 border-b-2 border-white" v-html="row.second"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    table: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
