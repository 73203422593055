<template>
  <div class="relative">
    <CldnImg
      v-if="image[0]"
      :src="image[0].url"
      :alt="alt ? alt : image[0].title"
      crop="fill"
      sizes="100vw"
      class="background-image h-[120vw] lg:h-[80vh] min-h-[400px] object-cover w-full"
    />
    <div class="absolute inset-0">
      <div class="lg:items-start py-l flex items-end justify-end h-full">
        <BarText v-if="heading" tag="h1" :text="heading" class="fluid-5xl leading-none text-right" />
      </div>
    </div>
  </div>
</template>

<script>
import BarText from '@/components/BarText';
import CldnImg from '@/components/CldnImg';

export default {
  components: {
    BarText,
    CldnImg,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    image: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
