<template>
  <svg viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
      <circle stroke="#162022" cx="19.5" cy="19.5" r="19.5" />
      <path
        d="M26.26 16.485c.01.152.01.304.01.457C26.27 21.613 22.762 27 16.346 27v-.003A9.778 9.778 0 0 1 11 25.413a6.944 6.944 0 0 0 5.162-1.465 3.498 3.498 0 0 1-3.259-2.455 3.432 3.432 0 0 0 1.575-.061c-1.627-.333-2.798-1.782-2.798-3.465v-.045a3.43 3.43 0 0 0 1.583.443c-1.532-1.038-2.005-3.105-1.08-4.72a9.856 9.856 0 0 0 7.189 3.693 3.568 3.568 0 0 1 1.009-3.377 3.459 3.459 0 0 1 4.934.153 6.942 6.942 0 0 0 2.215-.858 3.54 3.54 0 0 1-1.533 1.955A6.864 6.864 0 0 0 28 14.654a7.14 7.14 0 0 1-1.74 1.831Z"
        fill="#162022"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>
