<template>
  <svg viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)">
        <circle stroke="#162022" cx="19.5" cy="19.5" r="19.5" />
      </g>
      <g fill="#162022" fill-rule="nonzero">
        <path
          d="M14.955 11.32c-1.182 0-1.955.747-1.955 1.728 0 .96.75 1.728 1.91 1.728h.022c1.206 0 1.956-.768 1.956-1.728-.023-.981-.75-1.728-1.933-1.728ZM13 16.504h3.456v9.936H13zM24.642 16.072c-1.833 0-3.061 1.707-3.061 1.707v-1.47h-3.397v10.13h3.396v-5.656c0-.303.023-.605.112-.822.246-.605.805-1.231 1.743-1.231 1.23 0 1.72.929 1.72 2.29v5.42h3.397V20.63c0-3.111-1.676-4.559-3.91-4.559Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>
