<template>
  <div v-if="showInContentNav">
    <ChapterNavBar :chapters="chapters" :title="title" :class="{ 'opacity-0 invisible': !showNavBar }" />
    <!--    <div class="invisible opacity-0" />-->
    <div class="mt-12 fluid-xs leading-tightest font-medium">{{ $t('chapterTitle') }}</div>
    <div class="sm:flex-row flex flex-col">
      <div v-for="(column, idx) in columns" :key="idx" class="sm:w-1/2">
        <div v-for="{ chapterId, index, summaryTitle } in column" :key="chapterId" class="hover:opacity-80 px-1">
          <a
            v-if="chapterId"
            :href="`#${chapterId}`"
            class="block py-4 fluid-xs leading-tightest border-b border-gray-400"
            @click.prevent="onChapterClick(chapterId)"
          >
            <span v-if="index" class="font-medium">{{ index | indexPad }}.</span>
            {{ summaryTitle }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { indexPad, scrollToSelectorRespectingBars } from '@/utils/common'
import ChapterNavBar from '@/components/article/ChapterNavBar'

/**
 * shows in-content-nav and fixed-header-navbar for chapters in articleblocks
 * hidden by default, visible if more than one chapters
 */
export default {
  name: 'ChapterNav',
  filters: { indexPad },
  components: {
    ChapterNavBar
  },
  props: {
    blocks: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showNavBar: false,
      scrollToSelectorRespectingBars
    }
  },

  computed: {
    /**
     * don't show inConentMenu for only one chapter
     */
    showInContentNav() {
      return this.chapters.length >= 2
    },
    chapters() {
      return this.blocks.filter((block) => block.typeHandle === 'articleChapter')
    },
    /**
     * split chapters into colums
     * @returns {array} of arrays of chapters
     */
    columns() {
      const m = Math.ceil(this.chapters.length / 2)
      return [this.chapters.slice(0, m), this.chapters.slice(m)]
    }
  },
  mounted() {
    this.mountObserver()
  },
  methods: {
    onChapterClick(selector) {
      scrollToSelectorRespectingBars(`#${selector}`)
    },
    /**
     * observes in-content-nav, shows ChapterNavBar if view is scrolled below in-content-nav
     */
    mountObserver() {
      const handler = (entries) => {
        const inlineNav = entries[0]
        // only show if in-content-nav is out of viewport and scrollposition is lower thand in-content-nav
        if (!inlineNav.isIntersecting && inlineNav.boundingClientRect.top < 0) {
          this.showNavBar = true
        } else {
          this.showNavBar = false
        }
      }
      // don't run without chapters
      if (this.showInContentNav) {
        const observer = new window.IntersectionObserver(handler)
        // ref is component, we need to pass it's element

        observer.observe(this.$el)

        this.$once('hook:beforeDestroy', () => {
          observer.disconnect()
        })
      }
    }
  }
}
</script>
