<template>
  <div
    id="ChapterNavBar"
    class="bg-blackly lg:top-header-bar-height top-header-bar-height-mobile fixed left-0 z-30 w-screen text-white"
  >
    <div class="container flex flex-row justify-end h-12">
      <div class="md:block hidden text-sm">{{ title }}</div>
      <client-only>
        <button
          v-if="webShareApiSupported"
          class="navbar-button flex items-center justify-center h-4 border-gray-600"
          @click="shareArticleOnMobile"
        >
          <ShareIcon class="h-4" />
        </button>
        <a v-if="!webShareApiSupported" href="#shareThisArticle" class="lg:hidden block border-gray-600 border-x">
          <button class="flex items-center justify-center w-12 h-12"><ShareIcon class="h-4" /></button>
        </a>
      </client-only>
      <div class="sm:max-w-[200px] lg:max-w-[300px] bg-blackly relative z-10 flex flex-col w-full text-left">
        <button
          id="options-menu"
          type="button"
          class="text-br-green-light hover:text-white outline-none focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-blue flex items-center justify-between w-full h-full px-4 fluid-xs leading-tightest uppercase transition duration-150 ease-in-out"
          aria-haspopup="true"
          @click="open = !open"
        >
          <div>{{ $t('chapterTitle') }}</div>
          <DropdownIndicatorIcon :down="!open" class="block mt-1 h-1.5" />
        </button>
        <div v-show="open" v-on-click-outside="() => (open = false)" :aria-expanded="open">
          <div
            class="absolute right-0 w-full text-black origin-top-right bg-white shadow-lg"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div v-for="(chapter, idx) in chapters" :key="idx">
              <a
                :href="`#${chapter.chapterId}`"
                class="block p-4 fluid-xs leading-tightest border-gray-300"
                role="menuitem"
                :class="[idx < chapters.length - 1 ? 'border-b' : '']"
                @click="onChapterClick($event, chapter.chapterId)"
              >
                <span class="font-medium">{{ chapter.index | indexPad }}.</span>
                {{ chapter.summaryTitle }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChapterNavBarProgress />
  </div>
</template>

<script>
import { indexPad, scrollToSelectorRespectingBars } from '@/utils/common'
import { mixin as onClickOutside } from 'vue-on-click-outside'
import ChapterNavBarProgress from '@/components/article/ChapterNavBarProgress'
import ShareIcon from '@/components/icons/ShareIcon'

import DropdownIndicatorIcon from '@/components/icons/DropdownIndicatorIcon'
export default {
  name: 'ChapterNavBar',
  components: { ShareIcon, ChapterNavBarProgress, DropdownIndicatorIcon },
  filters: { indexPad },
  mixins: [onClickOutside],

  props: {
    chapters: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return { open: false }
  },
  computed: {
    shareData() {
      return {
        title: document.title,
        text: this.$t('shareThisArticleMessage'),
        url: location.href
      }
    },
    webShareApiSupported() {
      if (process.server || !window.navigator) return false
      return !!window.navigator?.share
    }
  },
  methods: {
    shareArticleOnMobile() {
      if (!this.webShareApiSupported) return false
      window.navigator.share(this.shareData).catch((error) => {
        console.warn(error)
      })
    },
    onChapterClick(e, selector) {
      e.preventDefault()
      scrollToSelectorRespectingBars(`#${selector}`)
      this.open = false
    }
  }
}
</script>
