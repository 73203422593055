<template>
  <div>
    <div class="container">
      <ChapterNav :blocks="blocksWithIteratedChapter" :title="title" class="max-w-screen-md mx-auto" />
    </div>
    <div v-for="(block, index) in blocks" :key="index">
      <!-- special handling for Table component since it is reserved tag in HTML -->
      <Table v-if="block.typeHandle === 'table'" v-bind="block" />
      <component :is="block.typeHandle" v-else-if="isVisible(block)" v-bind="{ ...block, blockPosition: index }" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import guestComponent from '@/mixins/guestComponent';

import ChapterNav from '@/components/article/ChapterNav';
import FeaturedArticlesSlide, {
  TopicBlocksFeaturedArticlesSlideFragment,
} from '@/components/common/blocks/FeaturedArticlesSlide';
import ArticleRowSlide, { MarketingBlocksArticleRowSlideFragment } from '@/components/common/blocks/ArticleRowSlide';
import ArticleChapter, { ArticleBlocksArticleChapterFragment } from '@/components/common/blocks/ArticleChapter';
import CopyText, { MarketingBlocksCopyTextFragment } from '@/components/common/blocks/CopyText';
import ImageOrVideo, { MarketingBlocksImageOrVideoFragment } from '@/components/common/blocks/ImageOrVideo';
import Gallery, { GalleryFragment } from '~/components/common/blocks/Gallery';
import Accordion, { MarketingBlocksAccordionFragment } from '@/components/common/blocks/Accordion';
import InContentCta, { MarketingBlocksInContentCtaFragment } from '@/components/common/blocks/InContentCta';
import Testimonial, { ArticleBlocksTestimonialFragment } from '@/components/common/blocks/Testimonial';
import NumberedImage, { ArticleBlocksNumberedImageFragment } from '@/components/common/blocks/NumberedImage';
import SmallInstruction, { ArticleBlocksSmallInstructionFragment } from '@/components/common/blocks/SmallInstruction';
import FullWidthTeaserBox, {
  MarketingBlocksFullWidthTeaserBoxFragment,
} from '@/components/common/blocks/FullWidthTeaserBox';
import AccordionBlock, { ProductBlocksAccordionBlockFragment } from '@/components/common/blocks/AccordionBlock';
import InContentCtaBlocks, {
  MarketingBlocksInContentCtaBlocksFragment,
} from '@/components/common/blocks/InContentCtaBlocks';
import Table, { ArticleBlocksTableFragment } from '@/components/common/blocks/Table';
import ExpertDetailsListing, {
  ArticleBlocksExpertDetailsListingFragment,
} from '@/components/common/blocks/ExpertDetailsListing';
import FullWidthImage, { ArticleBlocksFullWidthImageFragment } from '@/components/common/blocks/FullWidthImage';
import FiftyFiftyTeaserImage, {
  ArticleBlocksFiftyFiftyTeaserImageFragment,
} from '@/components/common/blocks/FiftyFiftyTeaserImage';
import FeatureHeadline, { ArticleBlocksFeatureHeadlineFragment } from '@/components/common/blocks/FeatureHeadline';
import ImageText, { MarketingBlocksImageTextFragment } from '~/components/common/blocks/ImageText';
import FullWidthImageCta, {
  ArticleBlocksFullWidthImageCtaFragment,
} from '@/components/common/blocks/FullWidthImageCta';
import IncontentImageRow, {
  ArticleBlocksIncontentImageRowFragment,
} from '@/components/common/blocks/IncontentImageRow';
import ContentRecommendationSlide, {
  StoriesPageBlocksContentRecommendationSlideFragment,
} from '@/components/common/blocks/ContentRecommendationSlide';
import ExerciseRoutineCardsSliderWithText, {
  MarketingBlocksRoutineCardsSliderWithTextFragment,
} from '@/components/common/blocks/ExerciseRoutineCardsSliderWithText';
import ShareThisArticle, {
  ArticleBlocksShareThisArticleFragment,
} from '@/components/common/blocks/ShareThisArticle/index';
import ProductSlider, { MarketingBlocksProductSliderFragment } from '@/components/common/blocks/ProductSlider';
import ContentWithIcons, { ArticleBlocksContentWithIconsFragment } from '@/components/common/blocks/ContentWithIcons';
import AppCta, { MarketingBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import ContactBlock, { ArticleBlocksContactBlockFragment } from '@/components/common/blocks/ContactBlock';
import FormLink, { MarketingBlocksFormLinkFragment } from '@/components/common/blocks/FormLink';
import RoutineCardsSlider, {
  MarketingBlocksRoutineCardsSliderFragment,
} from '@/components/common/blocks/RoutineCardsSlider';
import AsSeenIn, { MarketingBlocksAsSeenInFragment } from '@/components/common/blocks/AsSeenIn';
import GenericIframe, { MarketingBlocksGenericIframeFragment } from '@/components/common/blocks/GenericIframe';
import NewsletterSignupForm, {
  MarketingBlocksNewsletterSignupFormFragment,
} from '@/components/common/blocks/NewsletterSignupForm';
import InsuranceReimbursement, {
  MarketingBlocksInsuranceReimbursementFragment,
} from '@/components/common/blocks/InsuranceReimbursement';

export const ArticleBlocksMatrixFragment = gql`
  fragment ArticleBlocksMatrixFragment on articleBlocks_MatrixField {
    ...TopicBlocksFeaturedArticlesSlideFragment
    ...MarketingBlocksArticleRowSlideFragment
    ...ArticleBlocksArticleChapterFragment
    ...MarketingBlocksCopyTextFragment
    ...MarketingBlocksImageOrVideoFragment
    ...GalleryFragment
    ...MarketingBlocksAccordionFragment
    ...MarketingBlocksInContentCtaFragment
    ...ArticleBlocksTestimonialFragment
    ...ArticleBlocksNumberedImageFragment
    ...ArticleBlocksSmallInstructionFragment
    ...MarketingBlocksFullWidthTeaserBoxFragment
    ...ProductBlocksAccordionBlockFragment
    ...MarketingBlocksInContentCtaBlocksFragment
    ...ArticleBlocksTableFragment
    ...ArticleBlocksExpertDetailsListingFragment
    ...ArticleBlocksFullWidthImageFragment
    ...ArticleBlocksFiftyFiftyTeaserImageFragment
    ...ArticleBlocksFeatureHeadlineFragment
    ...MarketingBlocksImageTextFragment
    ...ArticleBlocksFullWidthImageCtaFragment
    ...ArticleBlocksIncontentImageRowFragment
    ...StoriesPageBlocksContentRecommendationSlideFragment
    ...MarketingBlocksRoutineCardsSliderWithTextFragment
    ...ArticleBlocksShareThisArticleFragment
    ...MarketingBlocksProductSliderFragment
    ...ArticleBlocksContentWithIconsFragment
    ...MarketingBlocksAppCtaFragment
    ...ArticleBlocksContactBlockFragment
    ...MarketingBlocksFormLinkFragment
    ...MarketingBlocksRoutineCardsSliderFragment
    ...MarketingBlocksAsSeenInFragment
    ...MarketingBlocksGenericIframeFragment
    ...MarketingBlocksNewsletterSignupFormFragment
    ...MarketingBlocksInsuranceReimbursementFragment
  }
  ${TopicBlocksFeaturedArticlesSlideFragment}
  ${MarketingBlocksArticleRowSlideFragment}
  ${ArticleBlocksArticleChapterFragment}
  ${MarketingBlocksCopyTextFragment}
  ${MarketingBlocksImageOrVideoFragment}
  ${GalleryFragment}
  ${MarketingBlocksAccordionFragment}
  ${MarketingBlocksInContentCtaFragment}
  ${ArticleBlocksTestimonialFragment}
  ${ArticleBlocksNumberedImageFragment}
  ${ArticleBlocksSmallInstructionFragment}
  ${MarketingBlocksFullWidthTeaserBoxFragment}
  ${ProductBlocksAccordionBlockFragment}
  ${MarketingBlocksInContentCtaBlocksFragment}
  ${ArticleBlocksTableFragment}
  ${ArticleBlocksExpertDetailsListingFragment}
  ${ArticleBlocksFullWidthImageFragment}
  ${ArticleBlocksFiftyFiftyTeaserImageFragment}
  ${ArticleBlocksFeatureHeadlineFragment}
  ${MarketingBlocksImageTextFragment}
  ${ArticleBlocksFullWidthImageCtaFragment}
  ${ArticleBlocksIncontentImageRowFragment}
  ${StoriesPageBlocksContentRecommendationSlideFragment}
  ${MarketingBlocksRoutineCardsSliderWithTextFragment}
  ${ArticleBlocksShareThisArticleFragment}
  ${MarketingBlocksProductSliderFragment}
  ${ArticleBlocksContentWithIconsFragment}
  ${MarketingBlocksAppCtaFragment}
  ${ArticleBlocksContactBlockFragment}
  ${MarketingBlocksFormLinkFragment}
  ${MarketingBlocksRoutineCardsSliderFragment}
  ${MarketingBlocksAsSeenInFragment}
  ${MarketingBlocksGenericIframeFragment}
  ${MarketingBlocksNewsletterSignupFormFragment}
  ${MarketingBlocksInsuranceReimbursementFragment}
`;

export default {
  name: 'ArticleBlocks',
  components: {
    GenericIframe,
    AsSeenIn,
    ShareThisArticle,
    ChapterNav,
    FeaturedArticlesSlide,
    ArticleRowSlide,
    ArticleChapter,
    CopyText,
    ImageOrVideo,
    Gallery,
    Accordion,
    InContentCta,
    Testimonial,
    NumberedImage,
    SmallInstruction,
    FullWidthTeaserBox,
    AccordionBlock,
    InContentCtaBlocks,
    Table,
    ExpertDetailsListing,
    FullWidthImage,
    FiftyFiftyTeaserImage,
    FeatureHeadline,
    ImageText,
    FullWidthImageCta,
    IncontentImageRow,
    ContentRecommendationSlide,
    ExerciseRoutineCardsSliderWithText,
    ProductSlider,
    ContentWithIcons,
    AppCta,
    ContactBlock,
    FormLink,
    RoutineCardsSlider,
    NewsletterSignupForm,
    InsuranceReimbursement,
  },

  mixins: [guestComponent],

  props: {
    title: {
      type: String,
      default: null,
    },
    blocks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    /**
     * attach ids and pass as a prop to chapter components for anchoring
     * autogenerate index for each article chapter to display a number
     * @returns {array} of objets of block
     */
    blocksWithIteratedChapter() {
      let chapterCount = 1;

      return this.blocks.map((block) => {
        if (block.typeHandle === 'articleChapter') {
          block.index = chapterCount++;
          block.chapterId = 'chapter-' + block.id;
        }

        return block;
      });
    },
  },
};
</script>
